import request from '@/utils/request.js';

const url = "/manage/applets/catalog";

//添加列表数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: params
    })
}

//删除当前列表
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}

/**
 * 获取树列表
 * @param  params
 * @returns {*}
 */
export function treeList(params) {
    // return request.post(`${url}/tree`,params)
    return request({
        url: `${url}/tree`,
        method: 'post',
        params: params
    })
}
/**
 * 更新树列表
 * @param  params
 * @returns {*}
 */
export function treeSort(params) {
    return request.post(`${url}/sort?parentId=${params.parentId}`,params.arr)
}