<template>
    <div>
        <el-form :model="drawerData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="目录名称" prop="name">
                <el-col :span="16">
                    <el-input v-model="drawerData.name" placeholder="请输入目录名称"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="目录类型" prop="name">
                <el-col :span="16">
                    <el-select v-model="drawerData.courseType" @change="chanageType" filterable class="condition-select"
                               placeholder="请选择"
                               size="small">
                        <el-option value="LIVE" label="回放"></el-option>
                        <el-option value="VIDEO" label="录播"></el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="userId" prop="userId">
                <el-col :span="16">
                    <el-input v-model="drawerData.userId" placeholder="userId"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item v-show="isVideoShow" label="播放码-录播" prop="playUrl">
                <el-col :span="16">
                    <el-input v-model="drawerData.playUrl" placeholder="请输入播放码" clearable></el-input>
                </el-col>
            </el-form-item>
            <div v-show="!isVideoShow">
                <el-form-item label="roomId" prop="roomId">
                    <el-col :span="16">
                        <el-input v-model="drawerData.roomId" placeholder="房间ID"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="recordId" prop="recordId">
                    <el-col :span="16">
                        <el-input v-model="drawerData.recordId" placeholder="回放ID"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="观看密码" prop="viewertoken">
                    <el-col :span="16">
                        <el-input v-model="drawerData.viewertoken" placeholder="观看密码"></el-input>
                    </el-col>
                </el-form-item>
            </div>
            <el-form-item label="初始点赞数" prop="initialPraise">
                <el-col :span="16">
                    <el-input v-model="drawerData.initialPraise" placeholder="请输入初始点赞数"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="初始在线人数" prop="initialOnline">
                <el-col :span="16">
                    <el-slider
                            v-model="drawerData.initialOnline"
                            range
                            show-stops
                            :max="2000">
                    </el-slider>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import * as api from "@/api/applets/appletsCatalog";

    export default {
        name: "drawerCont",
        props: {
            drawerData: {
                type: Object,
                require: true
            }
        },
        data() {
            return {
                isVideoShow: false,
                ruleForm: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: '',
                },
                rules: {
                    name: [
                        {required: true, message: '请输入活动名称', trigger: 'blur'}
                    ],
                    // teacherId: [
                    //   {required: true, message: '请选择一位讲课老师', trigger: 'change'}
                    // ],
                    // playUrl: [
                    //   {required: true, message: '请输入播放码', trigger: 'blur'}
                    // ],
                    initialPraise: [
                        {required: true, message: '请输入初始点赞数', trigger: 'blur'}
                    ],
                    initialOnline: [
                        {required: true, message: '请输入初始在线人数', trigger: 'blur'}
                    ],
                }
            }
        },
        watch: {
            drawerData(newData, oldData) {
                console.log(newData, oldData)
            }
        },
        methods: {
            getQueryVariable(url) {
                let u = decodeURI(url)
                let ul = u.split("?")[1]
                let mp = {}
                if (ul) {
                    let l = ul.split("&")
                    for (let i in l) {
                        let m = l[i].split("=")
                        mp[m[0]] = m[1]
                    }
                }
                return mp
            },
            open() {
                let that = this;
                this.$prompt('请输入回放地址', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({value}) => {
                    let m = this.getQueryVariable(value)
                    if (m) {
                        let liveid = m['liveid']
                        let recordid = m['recordid']
                        let userid = m['userid']
                        let roomid = m['roomid']
                        if (!that.drawerData) {
                            that.drawerData = {}
                        }
                        that.drawerData.userId = userid
                        that.drawerData.recordId = recordid
                        that.drawerData.roomId = roomid
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            },
            chanageType(type, isOpen) {
                if (type === 'LIVE') {
                    this.isVideoShow = false;
                    console.log(isOpen)
                    if (!isOpen) {
                        this.open()
                    }
                } else {
                    this.isVideoShow = true;
                }
            },
            submitForm(formName) {
                let that = this;
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        that.drawerData.initialOnline = that.drawerData.initialOnline.toString()
                        api.save(that.drawerData).then(res => {
                            console.log(res)
                            this.$message({
                                type: "success",
                                message: that.drawerData.id ? '修改成功！' : '新增成功！'
                            });
                            that.$emit('isDrawerShow')
                            that.$emit('treeList')
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        },
        mounted() {
        },
        created() {
            this.chanageType(this.drawerData.courseType, true);
            this.drawerData.initialOnline = JSON.parse("[" + this.drawerData.initialOnline + "]")
        },
    }
</script>

<style scoped>
    .line {
        text-align: center;
    }

    /deep/ .el-select {
        width: 100%;
    }

    /deep/ .el-input__inner {
        height: 24px;
        line-height: 24px;
    }

    /deep/ .el-form-item__label {
        font-size: 12px;
    }
</style>