<template>
    <div>
        <el-row style="margin-bottom: 10px">
            <el-button icon="el-icon-refresh-left" size="medium" type="info" plain @click="goBack">返回</el-button>
            <el-button icon="el-icon-plus" size="medium" type="primary" @click="nodePlus({},1)">新增</el-button>
        </el-row>
        <el-tree
                class="tree"
                :data="data"
                node-key="id"
                :props="defaultProps"
                default-expand-all
                @node-drag-end="handleDragEnd"
                @node-drop="handleDrop"
                draggable
                :allow-drop="allowDrop">
        <span class="custom-tree-node" slot-scope="{ node, data }" style="width:100%;" @mouseenter="mouseenter(data)"
              @mouseleave="mouseleave(data)">
          <span>{{ node.label }}</span>
             <el-link v-show="data.isShow && data.parentId === 0" size="mini" style="margin-left: 5px" type="primary"
                      icon="el-icon-plus" @click.stop="nodePlus(data)"></el-link>
             <el-link v-show="data.isShow" size="mini" style="margin-left: 5px" type="primary" icon="el-icon-edit"
                      @click.stop="nodeEdit(data)"></el-link>
             <el-link v-show="data.isShow" size="mini" style="margin-left: 5px" type="primary" icon="el-icon-delete"
                      @click.stop="nodeDelete(node,data)"></el-link>
          </span>
        </el-tree>

        <el-drawer
                :title="drawerTitle"
                :visible.sync="drawer"
                direction="rtl"
                size="40%">
            <drawerCont v-if="drawer" :drawerData="drawerData" @isDrawerShow="isDrawerShow"
                        @treeList="treeList"></drawerCont>
        </el-drawer>


    </div>

</template>

<script>
    import * as api from "@/api/applets/appletsCatalog";
    import * as domainApi from "@/api/mini/pagedomain";
    import * as apiUtils from "../../utils/api";
    import axios from "axios";
    import drawerCont from "@/views/applets/template/drawerCont";

    export default {
        name: "appletsCatalog",
        components: {
            drawerCont
        },
        data() {
            return {
                drawerData: new Object(),
                drawerTitle: '',
                drawer: false,
                data: [],
                defaultProps: {
                    children: 'catalogList',
                    label: 'name'
                }
            }
        },
        computed: {
            catalogueId: function () {
                return this.$route.query.id
            },
        },
        mounted() {
            this.treeList()
            // this.salesList();
            // this.getAdviserList();
            // this.getDomainList();
            // this.getPossList();
            // this.getMediatorList();
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
            handleDragStart(node, ev) {
                console.log('drag start', node);
            },
            handleDragEnter(draggingNode, dropNode, ev) {
                console.log('tree drag enter: ', dropNode.label);
            },
            handleDragLeave(draggingNode, dropNode, ev) {
                console.log('tree drag leave: ', dropNode.label);
            },
            handleDragOver(draggingNode, dropNode, ev) {
                // console.log('tree drag over: ', draggingNode, dropNode);

            },
            handleDragEnd(draggingNode, dropNode, dropType, ev) {
                console.log('tree drag end:1 ', dropNode , draggingNode, dropType);
                // if ('inner' === dropType && dropNode.data.parentId == 0) {
                //     let childId = draggingNode.data.id;
                //     let childNodes = dropNode.childNodes
                //     let childs = []
                //     for (let c in childNodes){
                //         childs.push(c.id)
                //     }
                //     childs.push(childId)
                //
                //     let obj = {
                //         parentId: dropNode.data.id,
                //         arr: childs
                //     }
                //     this.treeSortList(obj)
                // }
            },
            handleDrop(draggingNode, dropNode, dropType, ev) {
                console.log('tree drop: ', dropNode.label, dropType);
                /*var paramData = [];
                // 当拖拽类型不为inner,说明只是同级或者跨级排序，只需要寻找目标节点的父ID，获取其对象以及所有的子节点，并为子节点设置当前对象的ID为父ID即可
                // 当拖拽类型为inner，说明拖拽节点成为了目标节点的子节点，只需要获取目标节点对象即可
                let data = dropType != "inner" ? dropNode.parent.data : dropNode.data;
                let nodeData = dropNode.level == 1 && dropType != "inner" ? data : data.children;
                console.log(nodeData,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
                // 设置父ID,当level为1说明在第一级，pid为空
                nodeData.forEach(element => {
                  element.pid = data.id;
                });
                nodeData.forEach((element, i) => {
                  let dept = {
                    deptId: element.id,
                    parentDeptId: element.pid,
                    order: i
                  };
                  paramData.push(dept);
                });

                this.loading = true;*/

                // console.log(dropNode)   //dropNode.parent.childNodes =[] 拖拽之后的重新组合的数组 */
                let obj = {
                    parentId: '',
                    arr: []
                }
                // obj.parentId = dropNode.data.parentId
                obj.parentId = dropNode.childNodes.length === 0 ? dropNode.data.parentId : dropNode.data.id
                let node = dropNode.childNodes.length === 0 ? dropNode.parent.childNodes : dropNode.childNodes
                for (let item of node) {
                    // dropNode.parent.childNodes
                    obj.arr.push(item.data.id)
                }
                // console.log(obj,'paramDataparamDataparamDataparamData')
                this.treeSortList(obj)
            },
            allowDrop(draggingNode, dropNode, type) {
                if (draggingNode.level === 1 && dropNode.level === 2) {
                    return false
                }
                if (!dropNode.data.catalogList && dropNode.level === 2 || draggingNode.level === dropNode.level) {
                    return type !== 'inner';
                } else {
                    return true;
                }
                // console.log(draggingNode.data,dropNode.data)
                // if (draggingNode.level === dropNode.level) {
                //   console.log(true)
                //   //parentId 是父节点id
                //   if (draggingNode.data.parentId === dropNode.data.parentId) {
                //     return type === 'prev' || type === 'next'
                //   }else{
                //     return false;
                //   }
                // } else {
                //   // 不同级进行处理
                //   return false;
                // }
            },
            allowDrag(draggingNode) {
                // return draggingNode.data.label.indexOf('三级 3-2-2') === -1;
            },
            mouseenter(data) {
                // console.log(data)
                data.isShow = true
            },

            mouseleave(data) {
                // console.log(data)
                data.isShow = false
            },
            /**
             *获取树列表数据
             */
            treeList() {
                api.treeList({courseId: this.catalogueId}).then(res => {
                    console.log(res.data, '树列表数据')
                    this.data = res.data
                })
            },
            /**
             *更新排序树列表数据
             */
            treeSortList(params) {
                api.treeSort(params).then(res => {
                    console.log(res, '更新树列表数据')
                    // this.data = res.data
                    this.$message({
                        type: "success",
                        message: "排序更新成功！"
                    });
                    this.treeList()
                })
            },

            /**
             *新增数据
             */
            nodePlus(data, outer) {
                this.drawerTitle = '新增目录'
                this.drawer = true
                this.drawerData = {
                    name: '',
                    teacherId: '',
                    playUrl: '',
                    initialPraise: '',
                    initialOnline: '0,0',
                    isShow: false,
                    courseId: this.catalogueId,
                    parentId: data.id ? data.id : 0,
                    sort: outer ? this.data.length : (data.catalogList ? data.catalogList.length : 0),
                }
                console.log(data)
                console.log(this.drawerData)
            },
            /**
             * 修改数据
             */
            nodeEdit(data) {
                this.drawerData = new Object();
                this.drawerTitle = '修改目录'
                this.drawer = true
                console.log(data)
                this.drawerData = data

            },
            /**
             * 删除数据
             */
            nodeDelete(node, data) {
                console.log(node, data, 'hhh')
                const parent = node.parent;
                const children = parent.data.children || parent.data;

                api.deleteCurrent(data.id).then(res => {
                    console.log(res, 'shanchu')
                    this.$message({
                        type: "success",
                        message: "删除成功！"
                    });
                })
            },
            //新增
            addRoles() {
                this.dialogTitle = "添加微页面";
                this.dialogForm = Object.assign({}, this.dialogForm_null);
                this.dialogFormVisible = true;
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            edit(row, index) {
                console.log(row);
                this.dialogTitle = "修改微页面";
                this.dialogForm = Object.assign({}, row);
                this.dialogFormVisible = true;
                this.rowIndex = index;
            },

            /*
            * 新增/修改 保存列表数据
            * */
            save(roleForm) {
                console.log(this.dialogForm); //打印值到底存上了没有
                this.$refs[roleForm].validate(valid => {
                    if (valid) {
                        let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                        api.save(this.dialogForm).then(res => {
                            this.$message({
                                type: "success",
                                message: id ? "修改成功！" : "新增成功！"
                            });
                            this.dialogFormVisible = false;
                            this.treeList()
                        });

                    } else {
                        return false;
                    }
                });
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                api.deleteCurrent(id).then(res => {
                    console.log(res)
                    this.pagedomainList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            },
            /**
             * 弹窗开关
             */
            isDrawerShow() {
                this.drawer = false
            },
            // 隔行变色
            changeColor() {
                let content = document.getElementsByClassName('el-tree-node__content');
                for (let i = 0; i < content.length; i++) {
                    if (i % 2 === 0) {
                        content[i].style.background = '';
                    } else {
                        // content[i].style.background = '#efefef';
                        content[i].style.borderBottom = '1px solid #efefef';
                    }
                }
            },
        },
        updated() {
            this.changeColor();
        },
    }
</script>

<style scoped>
    .tree {
        width: 600px;
        margin: auto;
    }

    /deep/ .el-tree-node__content {
        height: 38px;
        border-bottom: 1px solid #efefef;
    }

    /deep/ .el-tree-node__children {
        margin: 0 2em;
        box-sizing: border-box;
    }

    /deep/ .el-tree-node__children .el-tree-node__content {
        padding-left: 0 !important;

    }
</style>
